import {
  ArrayInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ValidationErrorMessage,
  Validator,
  maxValue,
  minLength,
  minValue,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { Grid, Typography, Box, InputAdornment } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { RUBInputProps } from "../shared/RUBInput";
import { TariffInput } from "./TariffInput";

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("sales.tariff.edit", { record });
  } else {
    useDefineAppLocation("sales.tariff.create");
  }

  const dataDefaultValue = !props.edit
    ? [
        {
          daysFrom: 1,
          price: 100,
        },
      ]
    : [];

  return (
    <SimpleForm>
      <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {translate("resources.Tariff.fieldGroups.identity")}
          </Typography>
          <TextInput source="name" fullWidth validate={required()} />
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ReferenceInput source="city" reference="City">
                <SelectInput
                  optionText="name"
                  label={translate("resources.Tariff.fields.city")}
                  fullWidth
                  validate={required()}
                />
              </ReferenceInput>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ReferenceInput source="carType" reference="CarType">
                <SelectInput
                  optionText="name"
                  label={translate("resources.Tariff.fields.carType")}
                  fullWidth
                  validate={required()}
                />
              </ReferenceInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <TariffInput
              source="data"
              fullWidth
              defaultValue={dataDefaultValue}
            />
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <NumberInput source="deposit" fullWidth validate={required()} />
            </Box>
            <Box flex={1}>
              <NumberInput source="overrun" fullWidth validate={required()} />
            </Box>
          </Box>
          <TextInput source="description" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
