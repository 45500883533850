import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  Title,
  required,
  useAuthState,
  useEditController,
  TextField,
  SaveContextProvider,
  useDataProvider,
  useNotify,
  useGetIdentity,
  useTranslate,
  PasswordInput,
  email,
  ImageInput,
  ImageField,
  Error,
  useAuthenticated,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  Datagrid,
  ReferenceField,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  DeleteButton,
  FileField,
  FileInput,
  List,
  SaveButton,
  Toolbar,
  WithRecord,
  useGetOne,
  useRefresh,
  useGetList,
  EditBase,
  SelectField,
} from "react-admin";

import { ParseDataProvider } from "../data-provider";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  contactTypeChoises,
  docTemplateTypeChoises,
  partnerTypeChoises,
} from "../shared/select";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import {
  DocTemplateTypeEnum,
  DocTypeEnum,
  Partner,
  PartnerTypeEnum,
  _User,
} from "../types";
import { RemoveUserButton } from "../partners/RemoveUserButton";
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout/esm/src";
import { CustomToolbar } from "../partners/createEdit";
import { AddUSerButton } from "../partners/AddUserButton";
import { useMutation } from "react-query";
import UserNameField from "../users/UserNameField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { actFields, orderFields } from "./doc-fields";

const MyCompany = ({ ...props }) => {
  useAuthenticated();
  const refresh = useRefresh();
  const { isLoading, data } = useGetList("Partner");

  const translate = useTranslate();
  useDefineAppLocation("Company");

  if (!data) {
    return null;
  }

  const getTypeChoises = (companyType: PartnerTypeEnum) => {
    switch (companyType) {
      case PartnerTypeEnum.INDIVIDUAL:
        return docTemplateTypeChoises.filter(
          (value) =>
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_ORG
        );
      case PartnerTypeEnum.COMPANY:
        return docTemplateTypeChoises.filter(
          (value) =>
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_ORG
        );
      case PartnerTypeEnum.SELF_EMPLOYED:
        return docTemplateTypeChoises.filter(
          (value) =>
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_IP_TO_ORG
        );
      case PartnerTypeEnum.IP:
        return docTemplateTypeChoises.filter(
          (value) =>
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_ORG_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_ORG &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_INDIVIDUAL &&
            value.id !== DocTemplateTypeEnum.FROM_SELFEMPLOYED_TO_ORG
        );
    }
  };

  return (
    <>
      <EditBase resource="Partner" id={data[0].id} redirect={false}>
        <Card sx={{ mt: 1 }}>
          <TabbedForm>
            <TabbedForm.Tab label="resources.Partner.tabs.identity">
              <Grid container width={{ xs: "100%" }} spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.identity")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <SelectInput
                        source="type"
                        validate={required()}
                        choices={partnerTypeChoises}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="name"
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="shortName"
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <TextInput
                    source="fullName"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="positionOfHead"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="positionReason"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="lastnameAndInitials"
                    validate={[required()]}
                    fullWidth
                  />

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.accountInfo")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0 }}>
                      <TextInput
                        source="inn"
                        label={translate("resources.AccountInfo.fields.inn")}
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="kpp"
                        label={translate("resources.AccountInfo.fields.kpp")}
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="ogrn"
                        label={translate("resources.AccountInfo.fields.ogrn")}
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.bankInfo")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0 }}>
                      <TextInput
                        source="bik"
                        label={translate("resources.BankInfo.fields.bik")}
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="bankName"
                        label={translate("resources.BankInfo.fields.bankName")}
                        validate={required()}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <ReferenceInput source="bankCity" reference="City">
                        <SelectInput
                          optionText="name"
                          label={translate(
                            "resources.BankInfo.fields.bankCity"
                          )}
                          fullWidth
                          validate={required()}
                        />
                      </ReferenceInput>
                    </Box>
                  </Box>

                  <TextInput
                    source="bankAccount"
                    label={translate("resources.BankInfo.fields.bankAccount")}
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="korrAccount"
                    label={translate("resources.BankInfo.fields.korrAccount")}
                    validate={[required()]}
                    fullWidth
                  />

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.contacts")}
                  </Typography>
                  <ArrayInput source="contacts" label={false}>
                    <SimpleFormIterator
                      inline
                      disableClear
                      fullWidth
                      disableReordering
                    >
                      <SelectInput
                        source="type"
                        label={translate("resources.Partner.fields.type")}
                        validate={required()}
                        choices={contactTypeChoises}
                        sx={{ display: "flex", flex: 1 }}
                      />
                      <TextInput
                        source="value"
                        validate={required()}
                        sx={{ display: "flex", flex: 1 }}
                        label={translate("resources.Partner.fields.value")}
                      />
                      <TextInput
                        source="description"
                        validate={required()}
                        sx={{ display: "flex", flex: 1 }}
                        label={translate(
                          "resources.Partner.fields.description"
                        )}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.addressInfo")}
                  </Typography>
                  <ReferenceInput source="city" reference="City">
                    <SelectInput
                      optionText="name"
                      label={translate("resources.Partner.fields.city")}
                      fullWidth
                      validate={required()}
                    />
                  </ReferenceInput>
                  <TextInput
                    source="legalAddress"
                    label={translate("resources.Partner.fields.legalAddress")}
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source="actualAddress"
                    label={translate("resources.Partner.fields.actualAddress")}
                    validate={required()}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="resources.Partner.tabs.users">
              <Grid container width={{ xs: "100%" }} spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.users")}
                  </Typography>
                  <ArrayField source="users">
                    <Datagrid bulkActionButtons={false} sx={{ mb: 2 }}>
                      <ReferenceField
                        source="id"
                        reference="_User"
                        label="Номер"
                        link={false}
                      >
                        <UserNameField source="username" />
                      </ReferenceField>
                      <ReferenceField
                        source="id"
                        reference="_User"
                        label="Имя"
                        link={false}
                      >
                        <FunctionField
                          label="Имя"
                          render={(record: _User) =>
                            `${record.firstname} ${record.lastname}`
                          }
                        />
                      </ReferenceField>
                      <ReferenceArrayField
                        label="Роли"
                        reference="_Role"
                        source="roles"
                      >
                        <SingleFieldList linkType={false}>
                          <ChipField source="title" />
                        </SingleFieldList>
                      </ReferenceArrayField>
                      <ReferenceField
                        source="id"
                        reference="_User"
                        label="resources._User.fields.position"
                        link={false}
                      >
                        <TextField
                          source="position"
                          label="resources._User.fields.position"
                        />
                      </ReferenceField>

                      <RemoveUserButton />
                      <EditInDialogButton
                        fullWidth
                        maxWidth="md"
                        resource="_User"
                        title="resources._User.actions.editUserRolesTitle"
                        mutationOptions={{
                          onSettled: () => {
                            refresh();
                          },
                        }}
                      >
                        <SimpleForm toolbar={<CustomToolbar />}>
                          <TextInput
                            source="username"
                            disabled
                            fullWidth
                            label="resources._User.fields.username"
                          />
                          <TextInput
                            source="position"
                            fullWidth
                            label="resources._User.fields.position"
                          />
                          <ReferenceArrayInput source="roles" reference="_Role">
                            <SelectArrayInput
                              optionText="title"
                              label="resources._User.tabs.roles"
                              fullWidth
                            />
                          </ReferenceArrayInput>
                        </SimpleForm>
                      </EditInDialogButton>
                    </Datagrid>
                    <AddUSerButton />
                  </ArrayField>
                </Grid>
              </Grid>
              <Box mt="4em" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="resources.Partner.tabs.doc_templates">
              <Grid container width={{ xs: "100%" }} spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.Partner.fieldGroups.doc_templates")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <ImageInput
                        source="logo"
                        maxSize={100000}
                        accept={
                          "image/jpg,image/jpeg,image/heic,image/heif,image/png"
                        }
                      >
                        <ImageField source="src" />
                      </ImageInput>
                    </Box>
                  </Box>

                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    Памятка по использованию шаблонов
                  </Typography>

                  <Box mt="1em" />

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Параметры шаблона договоров
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        {orderFields.map((field, index) => (
                          <li key={index}>
                            <strong>{field.name}</strong>: {field.description}
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Параметры шаблона актов
                    </AccordionSummary>
                    <AccordionDetails>
                    <ul>
                        {actFields.map((field, index) => (
                          <li key={index}>
                            <strong>{field.name}</strong>: {field.description}
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>

                  <WithRecord
                    render={(record: Partner) => {
                      return (
                        <>
                          <List
                            resource="DocTemplate"
                            exporter={false}
                            sort={{ field: "createdAt", order: "DESC" }}
                            empty={false}
                          >
                            <Datagrid bulkActionButtons={false}>
                              <TextField source="name" />
                              <SelectField
                                source="type"
                                choices={docTemplateTypeChoises}
                              />
                              <FileField
                                source="file._url"
                                label={"resources.DocTemplate.fields.file"}
                                title="file._name"
                                target="_blank"
                              />
                              <EditInDialogButton>
                                <SimpleForm
                                  toolbar={
                                    <Toolbar
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <SaveButton />
                                      <DeleteButton redirect={false} />
                                    </Toolbar>
                                  }
                                >
                                  <TextInput
                                    source="name"
                                    validate={required()}
                                    fullWidth
                                    label={translate(
                                      "resources.DocTemplate.fields.name"
                                    )}
                                  />
                                  <SelectInput
                                    source="type"
                                    choices={getTypeChoises(record.type)}
                                    validate={required()}
                                    fullWidth
                                  />
                                  <FileInput
                                    source="file"
                                    validate={required()}
                                    fullWidth
                                    accept=".docx"
                                    label={translate(
                                      "resources.DocTemplate.fields.file"
                                    )}
                                  >
                                    <FileField
                                      source="_url"
                                      src="_url"
                                      title="_name"
                                      target="_blank"
                                    />
                                  </FileInput>
                                </SimpleForm>
                              </EditInDialogButton>
                            </Datagrid>
                          </List>
                          <CreateInDialogButton
                            resource="DocTemplate"
                            fullWidth
                          >
                            <SimpleForm resource="DocTemplate">
                              <TextInput
                                source="name"
                                validate={required()}
                                fullWidth
                                label={translate(
                                  "resources.DocTemplate.fields.name"
                                )}
                              />
                              <SelectInput
                                source="type"
                                choices={getTypeChoises(record.type)}
                                validate={required()}
                                fullWidth
                              />
                              <FileInput
                                source="file"
                                validate={required()}
                                fullWidth
                                accept=".docx"
                                label={translate(
                                  "resources.DocTemplate.fields.file"
                                )}
                              >
                                <FileField source="src" title="title" />
                              </FileInput>
                            </SimpleForm>
                          </CreateInDialogButton>
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt="4em" />
            </TabbedForm.Tab>
          </TabbedForm>
        </Card>
      </EditBase>
    </>
  );
};

export default MyCompany;
