import "./global.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/themes/material.css";
import * as React from "react";
import {
  CustomRoutes,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  Resource,
  localStorageStore,
  resolveBrowserLocale,
  defaultTheme,
} from "react-admin";
import { Admin, buildI18nProvider } from "@react-admin/ra-enterprise";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Route } from "react-router";
import authProvider from "./auth-provider";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";

import dataProvider from "./data-provider";
import cities from "./cities";
import users from "./users";
import autoBrands from "./auto-brands";
import autoModels from "./auto-models";
import partnerCars from "./cars";
import partners from "./partners";
import customers from "./customers";
import addServices from "./additional-services";
import maintenance from "./maintenance";
import maintenanceItem from "./maintenance-item";
import customerSegments from "./customer-segments";
import images from "./image";
import documents from "./document";
import tariffs from "./tariff";
import carTypes from "./car-type";
import caEquipment from "./equipment";
import docTemplate from "./doc-template";
import orders from "./orders";
import transactions from "./transaction";
import transactionAccounts from "./transaction-account";
import act from "./act";
import accidents from "./accident";

import customEnglishMessages from "./i18n/en";
import customRussianMessages from "./i18n/ru";
import { getThemes } from "./layout/theme/themes";
import { createTheme, responsiveFontSizes } from "@mui/material";
import Profile from "./profile/Profile";
import MyCompany from "./my-company";
import InsuranceList from "./cars/reports/insurance/InsuranceList";
import InspectionList from "./cars/reports/technicalInspection/InspectionList";

const languages = [
  { locale: "en", name: "English" },
  { locale: "ru", name: "Русский" },
];

const messages = {
  en: customEnglishMessages,
  ru: customRussianMessages,
};

const i18nProvider = buildI18nProvider(
  messages,
  resolveBrowserLocale(),
  languages
);

const App = () => {
  let theme = createTheme(defaultTheme);
  theme = responsiveFontSizes(theme);
  const { darkTheme, lightTheme } = getThemes(theme);
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      requireAuth
      dashboard={Dashboard}
      loginPage={Login}
      i18nProvider={i18nProvider}
      disableTelemetry
      layout={Layout}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      defaultTheme={false}
    >
      <Resource name="City" {...cities} />
      <Resource name="_User" {...users} />
      <Resource name="_Role" />
      <Resource name="AutoBrand" {...autoBrands} />
      <Resource name="AutoModel" {...autoModels} />
      <Resource name="PartnerCar" {...partnerCars} />
      <Resource name="Partner" {...partners} />
      <Resource name="AccountInfo" />
      <Resource name="Customer" {...customers} />
      <Resource name="Country" />
      <Resource name="AdditionalService" {...addServices} />
      <Resource name="Maintenance" {...maintenance} />
      <Resource name="MaintenanceItem" {...maintenanceItem} />
      <Resource name="CustomerSegment" {...customerSegments} />
      <Resource name="Image" {...images} />
      <Resource name="Doc" {...documents} />
      <Resource name="Tariff" {...tariffs} />
      <Resource name="CarType" {...carTypes} />
      <Resource name="CarEquipment" {...caEquipment} />
      <Resource name="DocTemplate" {...docTemplate} />
      <Resource name="Order" {...orders} />
      <CustomRoutes>
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Company/*" element={<MyCompany.edit />} />
        <Route path="/cars/report/insurance" element={<InsuranceList />} />
        <Route path="/cars/report/to_inspection" element={<InspectionList />} />
      </CustomRoutes>
      <Resource name="Notification" />
      <Resource name="TransactionType" />
      <Resource name="Transaction" {...transactions} />
      <Resource name="TransactionAccount" {...transactionAccounts} />
      <Resource name="CarEvent" />
      <Resource name="Act" {...act} />
      <Resource name="PhoneCheck"  />
      <Resource name="Accident" {...accidents} />
    </Admin>
  );
};

export default App;
