import {
  ArrayInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  ValidationErrorMessage,
  Validator,
  maxValue,
  minLength,
  minValue,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { Grid, Typography, Box, InputAdornment } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { CarInput } from "../shared/CarInput";
import { CustomerReferenceInput } from "../shared/CustomerReferenceInput";
import { RUBInputProps } from "../shared/RUBInput";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import { DocTypeEnum } from "../types";

export const AccidentCreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();


  return (
    <TabbedForm>
      <TabbedForm.Tab label="resources.Accident.tabs.identity">
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Accident.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="date"
                  fullWidth
                  validate={required()}
                  defaultValue={new Date()}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="cost"
                  label="resources.Accident.fields.cost"
                  helperText={false}
                  min={100}
                  validate={[required(), minValue(100)]}
                  InputProps={RUBInputProps}
                  fullWidth
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <CustomerReferenceInput source="customer" isRequired />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <CarInput source="car" isRequired />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="city" reference="City">
                  <SelectInput
                    optionText="name"
                    label={translate("resources.Accident.fields.city")}
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="place" fullWidth validate={required()} />
              </Box>
            </Box>

            <TextInput source="description" fullWidth multiline />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      {props.edit && (
        <TabbedForm.Tab label="resources.Accident.tabs.docs">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Accident.fieldGroups.docs")}
              </Typography>
              <DocsReferenceInput
                source={DocTypeEnum.ACCIDENT}
                target="accidentId"
              />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};
