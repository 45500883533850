import {
  Button,
  DateTimeInput,
  FormDataConsumer,
  Loading,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useGetList,
  useNotify,
  useRecordContext,
  useTranslate,
} from "react-admin";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  Stack,
  Typography,
  IconButton,
  Divider,
  useMediaQuery,
  Theme,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  alpha,
  MenuProps,
  Menu,
  styled,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useCallback, useRef, useState } from "react";
import { ParseDataProvider } from "../data-provider";
import { useQuery } from "react-query";
import { PreviewDocParams } from "../doc-template";
import { DocTemplate, DocTemplateTypeEnum, OrderTypeEnum } from "../types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { options } from "@fullcalendar/core/preact.js";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  EditDialog,
  EditInDialogButton,
} from "@react-admin/ra-form-layout/esm/src";
import ThumbUp from "@mui/icons-material/ThumbUp";
import { OrderCloseSummary } from "./OrderCloseSummary";
import { set } from "lodash";

export const OrderActionsButton = (props: any) => {
  const translate = useTranslate();

  const { data, total, isLoading, error } = useGetList<DocTemplate>(
    "DocTemplate",
    {
      filter: {
        type: DocTemplateTypeEnum.FROM_INDIVIDUAL_TO_INDIVIDUAL,
        isActive: true,
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "updatedAt", order: "DESC" },
    }
  );

  if (isLoading) {
    return (
      <Button disabled size="medium" variant="contained" sx={{ mr: 2 }}>
        <>{translate("custom.actions.print")}</>
      </Button>
    );
  }

  if (error) {
    return (
      <Button disabled size="medium" variant="contained" sx={{ mr: 2 }}>
        <>{translate("custom.actions.print")}</>
      </Button>
    );
  }

  return (
    <SelectOrderActionButton
      options={data?.map((template) => {
        return {
          name: template.name,
          type: template.type,
          id: template.id.toString(),
        };
      })}
    />
  );
};

type PrintForm = {
  name: string;
  type: DocTemplateTypeEnum;
  id: string;
};

type SelectPrintFormButtonProps = {
  options: PrintForm[] | undefined;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function SelectOrderActionButton({
  options,
}: SelectPrintFormButtonProps) {
  const record = useRecordContext();
  const notify = useNotify();
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider<ParseDataProvider>();

  const handlePrint = (option: PrintForm) => {
    handleClose();
    setIsLoading(true);
    const payload: PreviewDocParams = {
      templateId: option.id,
      docId: record.id.toString(),
    };
    dataProvider
      .previewDocument(payload)
      .then((file: Parse.File) => {
        const fileURL = file.url();
        setIsLoading(false);
        openLink(fileURL);
      })
      .catch((e) => {
        setIsLoading(false);
        notify(e.message ?? "resources.Order.notifications.doc_preview_error", {
          type: "error",
          undoable: false,
        });
      });
  };

  const openLink = (url: string) => window.open(url, '_blank')?.focus();

  const handleOpenEditDialog = () => {
    handleClose();
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    handleClose();
    setOpenEditDialog(false);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        size="medium"
        sx={{ mr: 2 }}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={isLoading}
      >
        <>{translate("custom.actions.docActions")}</>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FormDataConsumer<{ type: string; isClosed: boolean }>>
          {({ formData }) => {
            return (
              formData.type === OrderTypeEnum.ORDER &&
              formData.isClosed == false && (
                <MenuItem onClick={handleOpenEditDialog} disableRipple>
                  <ThumbUp />
                  {translate("resources.Order.action.closeOrder")}
                </MenuItem>
              )
            );
          }}
        </FormDataConsumer>
        <Divider sx={{ my: 0.5 }} />
        {options?.map((option) => {
          return (
            <MenuItem key={option.id} onClick={() => handlePrint(option)} disableRipple>
              <PrintIcon />
              {option.name}
            </MenuItem>
          );
        })}
      </StyledMenu>
      <EditDialog
        mutationMode="pessimistic"
        open={handleOpenEditDialog}
        close={handleCloseEditDialog}
        isOpen={openEditDialog}
        maxWidth="md"
        fullWidth
      >
        <SimpleForm
          toolbar={
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <SaveButton label="resources.Order.action.closeOrder" />
            </Toolbar>
          }
        >
          <DateTimeInput source="closedAt" fullWidth validate={[required()]} />
          <Box width={{ xs: "100%" }}>
            <OrderCloseSummary fullWidth />
          </Box>
        </SimpleForm>
      </EditDialog>
    </div>
  );
}
