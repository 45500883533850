import { CreateButton, TopToolbar } from "react-admin";
import { AddAccidentButton } from "./AddAccidentButton";

export const AccidentsListActions = (props: any) => {
  return (
    <TopToolbar>
      <AddAccidentButton/>
    </TopToolbar>
  );
};
