import DocIcon from "@mui/icons-material/DocumentScanner";

import {
  AutoBrand,
  AutoModel,
  CarType,
  City,
  DocTemplate,
  DocTemplateTypeEnum,
  ParsePointer,
  PartnerCar,
} from "../types";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneResult,
  RaRecord,
  ResourceCallbacks,
  UpdateParams,
} from "react-admin";
import { ParseDataProvider } from "../data-provider";
import DocTemplateEdit from "./DocTemaplateEdit";

const docTemplateResource = {
  edit: DocTemplateEdit,
  icon: DocIcon,
  recordRepresentation: (record: DocTemplate) => `${record.name}`,
};

export default docTemplateResource;

export type PreviewDocParams = {
  templateId: string;
  docId: string;
};

export const docTemplatesLifecycleCallbacks = {
  resource: "DocTemplate",

  afterRead: async (record: any, dataProvider: ParseDataProvider) => {
    return {
      ...record,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    if (data.file.rawFile) {
      const uploadedFile = await dataProvider.uploadFile(data.file.rawFile);
      return { ...data, file: uploadedFile };
    } else {
      delete data.file;
      return data;
    }
  },
};
