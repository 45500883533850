import { Button, useRecordContext, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import { PartnerCar } from "../types";
import AddIcon from "@mui/icons-material/Add";
import { stringify } from "querystring";

export const AddAccidentButton = (props: any) => {
  const record = useRecordContext<PartnerCar>();
  const tranlate = useTranslate();
  return (
    <Button
      to={{
        pathname: "/Accident/create",
        search: stringify({
          car_id: record.id,
        }),
      }}
      label={tranlate("ra.action.create")}
      component={Link}
    >
      <AddIcon />
    </Button>
  );
};
