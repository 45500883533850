import { Edit } from "react-admin";

import { AccidentCreateEdit } from "./AccidentCreateEdit";

export const AccidentEdit = () => {
  return (
    <Edit mutationMode="optimistic">
      <AccidentCreateEdit edit={true} />
    </Edit>
  );
};
