export const orderFields = [
  { name: "{PartnerFullName}", description: "Полное имя партнёра" },
  { name: "{CustomerFullFIO}", description: "Полное ФИО клиента" },
  { name: "{CustomerBirthDate}", description: "Дата рождения клиента" },
  { name: "{CustomerPassportSerie}", description: "Серия паспорта клиента" },
  { name: "{CustomerPassportNumber}", description: "Номер паспорта клиента" },
  {
    name: "{CustomerPassportDate}",
    description: "Дата выдачи паспорта клиента",
  },
  {
    name: "{CustomerPassportDepartment}",
    description: "Орган, выдавший паспорт клиента",
  },
  {
    name: "{CustomerPassportDepartmentCode}",
    description: "Код подразделения, выдавшего паспорт",
  },
  { name: "{CustomerPassportCountry}", description: "Гражданство клиента" },
  {
    name: "{CustomerDrivingLicenseNumber}",
    description: "Номер водительского удостоверения клиента",
  },
  {
    name: "{CustomerDrivingLicenseDate}",
    description: "Дата выдачи водительского удостоверения",
  },
  {
    name: "{CustomerDrivingLicenseDepartment}",
    description: "Орган, выдавший водительское удостоверение",
  },
  {
    name: "{CustomerDrivingLicenseEndDate}",
    description: "Дата окончания действия водительского удостоверения",
  },
  {
    name: "{CustomerDrivingLicenseAllowedCategories}",
    description: "Разрешённые категории транспортных средств по правам",
  },
  { name: "{CustomerLegalAddress}", description: "Юридический адрес клиента" },
  {
    name: "{CustomerActualAddress}",
    description: "Фактический адрес проживания клиента",
  },
  { name: "{CustomerPhone}", description: "Номер телефона клиента" },
  { name: "{CustomerContacts}", description: "Контактные данные клиента" },
  { name: "{CarMark}", description: "Марка автомобиля" },
  { name: "{CarModel}", description: "Модель автомобиля" },
  { name: "{CarYear}", description: "Год выпуска автомобиля" },
  { name: "{CarVIN}", description: "VIN номер автомобиля" },
  { name: "{CarRegNumber}", description: "Регистрационный номер автомобиля" },
  { name: "{CarColor}", description: "Цвет автомобиля" },
  { name: "{CarType}", description: "Тип автомобиля" },
  { name: "{CarOwner}", description: "Владелец автомобиля" },
  {
    name: "{CarRegistrationDocNumber}",
    description: "Номер документа о регистрации автомобиля",
  },
  { name: "{OrderNumber}", description: "Номер договора" },
  { name: "{OrderDate}", description: "Дата оформления договора" },
  { name: "{OrderDeliveryAddress}", description: "Адрес доставки" },
  { name: "{OrderDeliveryPrice}", description: "Стоимость доставки" },
  {
    name: "{OrderDeliveryPricePhrase}",
    description: "Стоимость доставки в текстовом формате",
  },
  { name: "{OrderTariff}", description: "Тариф на услугу" },
  { name: "{OrderTariffDescription}", description: "Описание тарифа" },
  { name: "{OrderStartDate}", description: "Дата начала действия договора" },
  { name: "{OrderEndDate}", description: "Дата окончания действия договора" },
  { name: "{OrderDeposit}", description: "Сумма залога" },
  {
    name: "{OrderDepositPhrase}",
    description: "Сумма залога прописью",
  },
  {
    name: "{OrderDepositDocuments}",
    description: "Документы, переданные в качестве залога",
  },
  {
    name: "{OrderDocumentAsDeposit}",
    description: "Используется ли документ в качестве залога",
  },
];

export const actFields = [
  {
    name: "{ActOrderNumber}",
    description: "Номер заказа, к которому относится акт",
  },
  {
    name: "{ActOrderDate}",
    description: "Дата оформления заказа, к которому относится акт",
  },
  { name: "{ActNumber}", description: "Номер акта" },
  { name: "{ActDate}", description: "Дата составления акта" },
  {
    name: "{ActType}",
    description: "Тип акта (например, приёмка, передача, промежуточный)",
  },
  {
    name: "{ActMileage}",
    description: "Пробег автомобиля на момент составления акта",
  },
  { name: "{ActEquipment}", description: "Комплектация автомобиля" },
];
