import { Datagrid, DateField, Filter, List, ReferenceField, SelectField, TextField } from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { AuthorInput } from "../shared/AuthorInput";
import { CarInput } from "../shared/CarInput";
import { CustomerReferenceInput } from "../shared/CustomerReferenceInput";

const Filters = (props: any) => (
  <Filter {...props}>
    <CustomerReferenceInput source="customer" alwaysOn/>
    <CarInput source="car" alwaysOn/>
    <AuthorInput source="author" alwaysOn/>
  </Filter>
);

export const AccidentList = () => {
  return (
    <List exporter={false} filters={<Filters />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="numStr" />
        <DateField source="date" />
        <ReferenceField source="car" reference="PartnerCar">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="customer" reference="Customer">
          <TextField source="fullname" />
        </ReferenceField>
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};