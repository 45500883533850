import Accident from "@mui/icons-material/CarCrash";

import { IAccident, ParsePointer } from "../types";
import {
  DataProvider,
} from "react-admin";
import { AccidentList } from "./AccidentList";
import { AccidentEdit } from "./AccidentEdit";
import { AccidentCreate } from "./AccidentCreate";
import { ParseDataProvider } from "src/data-provider";

const resource = {
  list: AccidentList,
  edit: AccidentEdit,
  create: AccidentCreate,
  icon: Accident,
  recordRepresentation: "numStr",
};

export const accidentsLifecycleCallbacks = {
  resource: "Accident",

  afterRead: async (record: IAccident, dataProvider: ParseDataProvider) => {
    return {
      ...record,
      author: record.author?.id,
      car: record.car?.id,
      customer: record.customer?.id,
      city: record.city?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.car = new ParsePointer("PartnerCar", data.car);
    data.customer = new ParsePointer("Customer", data.customer);
    data.city = new ParsePointer("City", data.city);
    return data;
  },
};

export default resource;
